import { HorizontalList } from '@canalplus/mycanal-sharedcomponent';
import { DEFAULT_RATIO } from '@dce-front/onewebapp-utils';
import classnames from 'classnames';
import type { JSX } from 'react';
import TemplateHeaderCanal from '../../../../components/TemplateHeader/TemplateHeader';
import { NavigationButton } from '../NavigationButton/NavigationButton';
import styles from './ContentRowGuide.css';

export type ContentRowGuideProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  isHorizontalList?: boolean;
  role?: React.AriaRole;
  title?: string;
  type?: string;
};

/**
 * Displays contents in a scrollable row.
 * If `title` , it renders also a header.
 *
 * @param title             The title of the contentRow
 * @param isHorizontalList  Put the children in a HorizontalList
 * @param children          Elements to render
 */
function ContentRowGuide({
  className = '',
  title,
  children,
  isHorizontalList = false,
  role,
}: ContentRowGuideProps): JSX.Element {
  return (
    <div
      className={classnames(styles.contentRowGuide, {
        [className]: !!className,
      })}
      {...(role && { role })}
    >
      {title && (
        <div className={styles.contentRowGuide__header}>
          <TemplateHeaderCanal title={title} />
        </div>
      )}
      {isHorizontalList && Array.isArray(children) && children.length && (
        <HorizontalList
          ratio={DEFAULT_RATIO}
          imageSize="normal"
          showControls={!$_BUILD_RENDERMODE_CSR}
          buttonPrevious={
            !$_BUILD_RENDERMODE_CSR ? (
              <NavigationButton type="previous" shouldHideOnMobile />
            ) : undefined
          }
          buttonNext={
            !$_BUILD_RENDERMODE_CSR ? (
              <NavigationButton type="next" shouldHideOnMobile />
            ) : undefined
          }
        >
          {children}
        </HorizontalList>
      )}
      {!isHorizontalList && (
        <div
          data-ratio={`${DEFAULT_RATIO}normal`}
          className={styles.contentRowGuide__rowWrapper}
          // don't add role="row" if container does not have role attribute
          {...(role && { role: 'row' })}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default ContentRowGuide;
