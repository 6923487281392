import loadable from '@loadable/component';
import Spinner from '../../components/Spinner/Spinner';

const LoadableEPGGrid = loadable(
  () => import('./components/EPGGridContainer'),
  {
    fallback: <Spinner />,
  },
);

export default LoadableEPGGrid;
