import classNames from 'classnames';
import type { JSX } from 'react';
import IconChevronLeft from '../../../../assets/svg/chevronLeft.svg';
import IconChevronRight from '../../../../assets/svg/chevronRight.svg';
import { useTranslation } from '../../../../lang';
import styles from './NavigationButton.css';

export type NavigationButtonProps = {
  shouldHideOnMobile?: boolean;
  type: 'previous' | 'next';
  onClick?: () => void;
};

export function NavigationButton({
  shouldHideOnMobile = false,
  onClick,
  type,
}: NavigationButtonProps): JSX.Element {
  const { t } = useTranslation();

  const title =
    type === 'previous'
      ? t('HorizontalPaging.previous')
      : t('HorizontalPaging.next');

  return (
    <button
      aria-label={title}
      className={classNames(
        styles.NavigationButton,
        styles[`NavigationButton--${type}`],
        {
          [styles['NavigationButton--shouldHideOnMobile']!]: shouldHideOnMobile,
        },
      )}
      onClick={onClick}
      title={title}
      type="button"
    >
      {type === 'previous' ? <IconChevronLeft /> : <IconChevronRight />}
    </button>
  );
}
